import React, { useEffect, useState, useRef, useContext, Fragment } from 'react';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoryTreeByStep, fetchChildCategories, fetchFullChildCategories } from '../../redux/action/category.action';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik';
import { searchProductPrice } from '../../redux/action/product-price.action';
import { Accordion, AccordionContext, Button, Card, useAccordionToggle } from 'react-bootstrap';
import JwksRsa from 'jwks-rsa';
import { Link, NavLink } from 'react-router-dom';
import Merkmal from './Merkmal.Component';
import json from 'highlight.js/lib/languages/json';
import { addToRfq, saveRfq } from '../../redux/action/rfq.action';
import c from 'highlight.js/lib/languages/c';
import { useTranslation } from 'react-i18next';

const SelectedParameters = (props) => {
  const currentCategory = props.category
  const dispatch = useDispatch();
  const merkmalenChildren = useSelector(state => state.category.merkmalenChildren);
  const { user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState('')
  const [collapse, setCollapse] = useState({})
  const { t, i18n } = useTranslation('common');

  let stepsArray = useSelector(state => state.steps.stepsArray);
  const { values, setFieldValue, submitForm, setValues } = useFormikContext();
  let aufpreis = useSelector(state => state.priceRule.currentAufpreisen);
  let aufpreisSeq = useSelector(state => state.priceRule.seq);
  let uiSeq = useSelector(state => state.ui.seq);

  useEffect(() => {
  }, [aufpreisSeq, uiSeq])

  return (
    <>
      {values &&
        <div className="">
          <table className="table table-hover table-job-positions wrap product-list">
            <thead>
              <tr>
                <th className="width-procent-20">Category</th>
                <th>Parameters</th>
                <th className="width-procent-20">Aufpreis</th>
              </tr>
            </thead>
            <tbody>
              {merkmalenChildren && merkmalenChildren.map((g, idx) => {
                return (
                  <Fragment key={`greoup_${idx}`}>
                    <tr><td colSpan={3} style={{ backgroundColor: "lightgray" }}><b>{t(`category_group.name.${_.snakeCase(g.name)}`)}</b></td></tr>
                    {g.subCategories && g.subCategories.map((s, index) => {

                      let aufPreisKey = ""
                      if (values["product"] && values["category_" + s.id]) {
                        aufPreisKey = `category_${values["category_" + s.id].category.id}_baugroesse_${values["product"].baugroesse}`
                      }
                      let preis = ""

                      if (aufpreis && aufpreis[aufPreisKey]?.aufpreis) {
                        if (aufpreis[aufPreisKey].aufpreis > 0) {
                          // if (typeof aufpreis[aufPreisKey].aufpreis == 'string') {
                          //   aufpreis[aufPreisKey].aufpreis = parseFloat(aufpreis[aufPreisKey].aufpreis);
                          // }
                          preis = aufpreis[aufPreisKey].aufpreis.toFixed(2)
                        } else if (aufpreis[aufPreisKey].aufpreis == 0) {
                          preis = 0
                        } else {
                          preis = -1
                        }
                      }

                      let docIds = [];
                      let docStr = "";
                      if (values["category_" + s.id]?.category.categoryType === "CHECKBOX") {
                        docIds = values["category_" + s.id].value.split(";").filter(Boolean).forEach(docId => {
                          docStr += values["category_" + s.id].category.properties.filter(p => p.id.toString() === docId).map(p => t(`category_property.name.${p.name}`)) + "<br />"
                        });
                      }

                      return (
                        <Fragment key={"category_check_" + s.id}>
                          {values["category_" + s.id] &&
                            <Fragment >
                              {values["category_" + s.id].category.categoryType === "CHECKBOX" &&
                                <tr>
                                  <td style={{ textAlign: "left" }}>{t(`category.name.${_.snakeCase(values["category_" + s.id].category.name)}`)}</td>
                                  <td dangerouslySetInnerHTML={{ __html: docStr }}></td>
                                  <td style={{ textAlign: "right" }}>{preis >= 0 ? preis > 0 ? `${preis} €` : '' : 'Auf Anfrage'}</td>
                                </tr>
                              }
                              {values["category_" + s.id].category.categoryType !== "CHECKBOX" &&
                                <tr>
                                  <td style={{ textAlign: "left" }}>{t(`category.name.${_.snakeCase(values["category_" + s.id].category.name)}`)}</td>
                                  <td>{t(`category_property.name.${values["category_" + s.id].value}`)} {values["category_" + s.id].category.einheit ? '(' + values["category_" + s.id].category.einheit + ')' : ''}</td>
                                  <td style={{ textAlign: "right" }}>{preis >= 0 ? preis > 0 ? `${preis} €` : '' : 'Auf Anfrage'}</td>
                                </tr>
                              }
                            </Fragment>
                          }
                        </Fragment>
                      )
                    })
                    }
                  </Fragment>
                )
              })}

            </tbody>
          </table>
        </div>
      }
    </>
  );
}

export default SelectedParameters;
