import React, { useEffect, useState, useRef } from 'react';
import { Button, Collapse, Modal, Table } from 'react-bootstrap';
import _ from 'lodash'
import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import Stepper from 'react-stepper-js'
import 'react-stepper-js/dist/index.css'
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { fetchClaimHistoryByClaimId } from '../../../redux/action/claim.action';
import moment from 'moment';

const ClaimHistory = (props) => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useAuth0();
  const history = useSelector(state => state.claims.currentHistory);

  useEffect(() => {
    dispatch(fetchClaimHistoryByClaimId({ id: props.claimId }))
  }, [])

  return (
    <>
      {isAuthenticated && user["https://atb.51jobs.de/roles"] && user["https://atb.51jobs.de/roles"].includes("claimInvestigator") &&
        <div className="row row-10">
          <div className='col-md-12'>
            <table className="table default-table">
              <thead><tr><th>Date</th><th>Status</th><th>User</th><th>Internal Comment</th><th>External Comment</th></tr></thead>
              <tbody>
                {history &&
                  history.map(h => {
                    return (
                      <tr key={"tr_" + h.id}>
                        <td>{moment(h.creationDate).format('YYYY-MM-DD HH:mm')}</td>
                        <td>{h.status}</td>
                        <td>{h.user.name}</td>
                        <td>{h.statusComment}</td>
                        <td>{h.statusCommentExternal}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      }

      {isAuthenticated && !user["https://atb.51jobs.de/roles"].includes("claimInvestigator") &&
        <div className="row row-10">
          <div className='col-md-12'>
            <table className="table default-table">
              <thead><tr><th>Date</th><th>Status</th><th>User</th><th>External Comment</th></tr></thead>
              <tbody>
                {history &&
                  history.map(h => {
                    return (
                      <tr key={"tr_" + h.id}>
                        <td>{moment(h.creationDate).format('YYYY-MM-DD  HH:mm')}</td>
                        <td>{h.status}</td>
                        <td>{h.user.name}</td>
                        <td>{h.statusCommentExternal}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      }
    </>
  )
}

export default ClaimHistory;
