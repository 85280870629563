import React, { useState } from "react";
import { NavLink, NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";

import Flags from 'country-flag-icons/react/3x2'

const NavBar = () => {
  const { t, i18n } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });
console.log(user);
  return (
    <header className="section page-header">
      <div className="rd-navbar-wrap">
        <nav className="rd-navbar rd-navbar-corporate" data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed" data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static" data-lg-device-layout="rd-navbar-fixed" data-xl-layout="rd-navbar-static" data-xl-device-layout="rd-navbar-fixed" data-lg-stick-up-offset="46px" data-xl-stick-up-offset="46px" data-xxl-stick-up-offset="46px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
          <div className="rd-navbar-aside-outer">
            <div className="rd-navbar-aside">
              <div className="rd-navbar-panel">
                <a className="rd-navbar-brand" href="/digicat/"><img src="/digicat/images/logo.png" alt="" width="171" height="39" /></a>

              </div>
              <div style={{ display: "inline-flex" , lineHeight:"50px"}}>
                <div style={{ width: "25px", cursor: "pointer" , marginTop:"15px"}} onClick={() => i18n.changeLanguage('de')}><Flags.DE /></div>
                <div className="ml10" style={{ width: "25px", cursor: "pointer", marginTop:"15px" }} onClick={() => i18n.changeLanguage('en')}><Flags.GB /></div>
                {isAuthenticated &&
                  <>
                    <NavDropdown className="navbarScrollingDropdown" title={<img
                      src={user.picture}
                      style={{ width: "50px", height: "50px", cursor: "pointer", marginLeft: "20px"}}
                      alt="Profile"
                      className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
                    />}>
                      <NavDropdown.Item href="/digicat/user/profile">{t('menu.profile')}</NavDropdown.Item>
                      <NavDropdown.Item href="/digicat/rfq-histories">{t('menu.rfqs')}</NavDropdown.Item>
                      <NavDropdown.Item href="/digicat/user/orders">{t('menu.orders')}</NavDropdown.Item>
                      <NavDropdown.Item href="/digicat/user/claims">{t('menu.claims')}</NavDropdown.Item>
                      <NavDropdown.Item onClick={(e) => {
                  e.preventDefault()
                  logoutWithRedirect({});
                }}>{t('menu.logout')}</NavDropdown.Item>
                    </NavDropdown>
                  </>
                }
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Navbar bg="light" expand="lg">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="container">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
          >
            <Nav.Link href="/" style={{ paddingLeft: "0px" }}>{t('menu.home')}</Nav.Link>
            <Nav.Link href="/digicat/configurator" >{t('menu.configurator')}</Nav.Link>
            <Nav.Link href="/digicat/leadtimes" >{t('menu.leadtimes')}</Nav.Link>
            <Nav.Link href="/digicat/user/claims/new" >{t('menu.claim')}</Nav.Link>

            {isAuthenticated && user["https://atb.51jobs.de/roles"] && user["https://atb.51jobs.de/roles"].includes("internal") &&
              <NavDropdownMenu title="Internal" className="navbarScrollingDropdown" style={{ marginLeft: "2.5em" }}>
                <DropdownSubmenu href="" title="Claims">
                  <NavDropdown.Item href="/digicat/claim/edit">{t('menu.add_new_claim')}</NavDropdown.Item>
                  <NavDropdown.Item href="/digicat/claims/status/draft">{t('menu.draft')}</NavDropdown.Item>
                  <NavDropdown.Item href="/digicat/claims/status/submitted" >{t('menu.submitted')}</NavDropdown.Item>
                  <NavDropdown.Item href="/digicat/claims/status/rejected" >{t('menu.rejected')}</NavDropdown.Item>
                  <NavDropdown.Item href="/digicat/claims/status/invalid" >{t('menu.invalid')}</NavDropdown.Item>
                </DropdownSubmenu>
                <NavDropdown.Item href="/digicat/internal/orders" >{t('menu.orders')}Orders</NavDropdown.Item>
              </NavDropdownMenu>
            }

            {isAuthenticated && user["https://atb.51jobs.de/roles"] && user["https://atb.51jobs.de/roles"].includes("claimInvestigator") &&
              <NavDropdownMenu title="Investigator" className="navbarScrollingDropdown">
                <NavDropdown.Item href="/digicat/claims/status/submitted" >{t('menu.submmitted')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/claims/status/accepted" >{t('menu.accepted')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/claims/status/retour" >{t('menu.retour')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/claims/status/replacement" >{t('menu.ersatzteil')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/claims/status/rejected" >{t('menu.reject')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/claims/status/finished" >{t('menu.finished')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/claims/status/archived" >{t('menu.archived')}</NavDropdown.Item>

              </NavDropdownMenu>
            }

            {isAuthenticated && user["https://atb.51jobs.de/roles"] && user["https://atb.51jobs.de/roles"].includes("admin") &&
              <NavDropdown title="Admin" className="navbarScrollingDropdown">
                <NavDropdown.Item href="/digicat/tree">{t('menu.tree_view')} </NavDropdown.Item>
                <NavDropdown.Item href="/digicat/categories">{t('menu.edit_categories')} </NavDropdown.Item>
                <NavDropdown.Item href="/digicat/admin/category-groups">{t('menu.edit_category_groups')}  </NavDropdown.Item>
                <NavDropdown.Item href="/digicat/rules">{t('menu.edit_rules')} </NavDropdown.Item>
                <NavDropdown.Item href="/digicat/admin/aufpreis-rules">{t('menu.edit_aufpreis')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/admin/reasons">{t('menu.claim_reasons')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/admin/users">{t('menu.users')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/admin/rfqs">{t('menu.rfqs')}</NavDropdown.Item>
                <NavDropdown.Item href="/digicat/admin/tranlations">{t('menu.translations')}</NavDropdown.Item>
              </NavDropdown>
            }

            {!isAuthenticated &&
              <Nav.Link href=""
                onClick={(e) => {
                  e.preventDefault()
                  loginWithRedirect({});
                }}>
                Login
              </Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>


  );
};

export default NavBar;
